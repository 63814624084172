import { connect } from 'react-redux';
import Minicart from '../../components/wrappers/Minicart';

import {
  updateProductQty,
  removeProduct,
  addDonation,
  removeDonation,
} from '../../store/actions/checkout';

const mapStateToProps = (state) => ({
  cartData: state.checkout.cart,
  grandTotal: state.checkout.cart.grandTotal,
  canCheckout: state.checkout.canCheckout,
  appError: state.checkout.appError,
});

const mapDispatchToProps = (dispatch) => ({
  itemUpdate: (qty, itemId) => dispatch(updateProductQty(qty, itemId)),
  removeProduct: (itemId) => dispatch(removeProduct(itemId)),
  addDonation: () => dispatch(addDonation()),
  removeDonation: () => dispatch(removeDonation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Minicart);
