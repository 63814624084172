import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import ProductList from '../../common/ProductList';
import styles from '../Steps/style.module.scss';
import minicartStyles from './style.module.scss';
import FormatPrice from '../../../helpers/FormatPrice';

import ExpressPaymentData from '../../../containers/express-payment/ExpressPaymentData';
import GiftAddress from '../../../helpers/GiftAddress';

const Minicart = ({
  currentStep,
  cartData,
  itemUpdate,
  setStep,
  setCheckingOut,
  grandTotal,
  expandCheckout,
  setSummaryExpanded,
  removeProduct,
  isClickAndCollect,
  fullPageCheckoutExperience,
  canCheckout,
  appError,
}) => {
  const { cart_type: cartType, items } = cartData;

  const isDev = (process.env.NODE_ENV === 'development');
  const useCoreCart = isDev ? false : window.genecheckout.useCoreCart;
  const coreCartUrl = isDev ? null : window.genecheckout.cartUrl;

  const [cartProductsHeight, setCartProductsHeight] = useState('');
  const cartActions = useRef(null);

  const showExpressPayment = () => grandTotal > 0
  && isClickAndCollect && canCheckout && !GiftAddress(cartData, isClickAndCollect);

  useLayoutEffect(() => {
    if (cartActions.current) {
      setCartProductsHeight(`calc(100vh - ${cartActions.current.offsetHeight}px)`);
    }
  }, [cartActions.current]);

  const proceed = () => {
    setCheckingOut(true);
    setStep(currentStep + 1);
    expandCheckout();

    if (fullPageCheckoutExperience && window.matchMedia('(min-width: 768px)').matches) {
      setSummaryExpanded(true);
    }
  };

  if (cartType === 'empty' || items.length === 0) {
    return (
      <p className={minicartStyles.cartEmpty}>Your cart is empty.</p>
    );
  }

  return (
    <div className={styles.step__inner}>
      <div className={minicartStyles.cartProducts} style={{ maxHeight: cartProductsHeight }}>
        {appError && (
          <div className={minicartStyles.minicartAppError}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><path d="M9.898.582c.214-.002.427.191.64.58A7104.263 7104.263 0 0019.26 17.02c.26.454.099.957-.577.957H1.123c-.572 0-.926-.33-.572-.957.237-.417 3.136-5.699 8.7-15.844.218-.394.434-.592.647-.594z" fill="#cd0d2c"/><g fill="#fff"><path d="M8.67 15.82v-1.367c0-.267.24-.48.506-.48H10.5c.267 0 .498.213.498.48v1.368c0 .267-.231.48-.498.48H9.176c-.266 0-.507-.19-.507-.48zM8.67 12.329V6.303c0-.267.24-.48.506-.48H10.5c.267 0 .498.213.498.48v6.026c0 .267-.231.48-.498.48H9.176c-.266 0-.507-.19-.507-.48z"/></g></g></svg>
            {appError}
          </div>
        )}
        <ProductList cartData={cartData} itemUpdate={itemUpdate} removeProduct={removeProduct} editable />
        {isDev && (
          <p>
            You cannot proceed to the core cart page in developer mode from the app.
            Please visit cart page manually.
          </p>
        )}
      </div>
      <div className={minicartStyles.minicartActions} ref={cartActions}>
        <div className={minicartStyles.minicartTotals}>
          <span className={minicartStyles.minicartTotals__label}>
            Total
          </span>
          <span className={minicartStyles.minicartTotals__amount}>
            {FormatPrice(grandTotal)}
          </span>
        </div>
        <div className={styles.step__nav}>
          {useCoreCart && (
          <a
            href={coreCartUrl}
            className={`action primary ${styles.step__next} ${minicartStyles.proceed}`}
            disabled={!canCheckout}
          >
            Proceed to Basket
          </a>
          )}

          {!useCoreCart && (
          <button
            className={`${styles.step__next} ${minicartStyles.proceed}`}
            type="button"
            onClick={() => proceed()}
            disabled={!canCheckout}
          >
            Proceed to Checkout
          </button>
          )}

          <p className={minicartStyles.continue}>
                or
            <br />
            <a href="/shop.html">CONTINUE SHOPPING</a>
          </p>

          {(showExpressPayment()) && (
            <ExpressPaymentData />
          )}
        </div>
      </div>
    </div>
  );
};

Minicart.propTypes = {
  currentStep: PropTypes.number,
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setStep: PropTypes.func,
  setCheckingOut: PropTypes.func,
  grandTotal: PropTypes.number,
  itemUpdate: PropTypes.func,
  expandCheckout: PropTypes.func,
  removeProduct: PropTypes.func,
  setSummaryExpanded: PropTypes.func,
  isClickAndCollect: PropTypes.bool,
  fullPageCheckoutExperience: PropTypes.bool,
  canCheckout: PropTypes.bool,
  appError: PropTypes.string,
};

Minicart.defaultProps = {
  currentStep: 0,
  cartData: {
    cart_type: '',
    items: [],
  },
  setStep: () => {},
  setCheckingOut: () => {},
  grandTotal: '',
  itemUpdate: () => {},
  expandCheckout: () => {},
  removeProduct: () => {},
  setSummaryExpanded: () => {},
  isClickAndCollect: false,
  fullPageCheckoutExperience: false,
  canCheckout: true,
  appError: '',
};


export default Minicart;
